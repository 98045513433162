import React from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import OrderPageModel from './Models/OrderPageModel.interface';
import { styled, theme } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { useKexNavigate } from '../Shared/KexRouter/KexRouter';
import { SmallButton } from '../Shared/Button/Button';
import ProductList from '../ProductList/ProductList';
import { media } from '@glitz/core';
import OrderDetailSummary from './OrderDetailSummary';
import { ReorderCart } from '../Cart/Cart';
import useEncodedCurrentUser from '../Shared/Hooks/useEncodedCurrentUser';

function OrderPage() {
  const {
    siteRoute,
    translations: { 'orderHistoryPage/addOrderItems': addOrderItemsText },
  } = useAppSettingsData();
  const currentPage = useCurrentPage<OrderPageModel>();
  const kexNavigate = useKexNavigate();
  const id = useEncodedCurrentUser();
  const order = currentPage.orderDetails;
  const addToCartClicked = () => {
    ReorderCart(order.orderNumber, id);
  };
  if (!order) {
    kexNavigate(`/${siteRoute}`);
    return <React.Fragment />;
  }
  return (
    <>
      <Container>
        <GridBox>
          <ListContainer>
            <Title>{currentPage.header}</Title>
            <ProductList
              products={order.lineItems}
              isLoading={false}
              allowEdit={false}
              isOrderHistory
            />
          </ListContainer>
        </GridBox>
        <OrderInfoWrapper>
          <AddAllOrderLinesToCart
            text={addOrderItemsText}
            onClick={addToCartClicked}
            isActive
          />
          {order && (
            <OrderDetailSummary order={order} company={currentPage.customer} />
          )}
        </OrderInfoWrapper>
      </Container>
    </>
  );
}
const Container = styled.div({
  color: theme.black,
  display: 'grid',
  gridGap: theme.spacing(4),
  gridTemplateColumns: '1fr',
  width: '100%',
  height: '100%',
  minHeight: '95vh',
  justifyContent: 'center',
  gridRowGap: theme.none,
  margin: {
    x: 'auto',
    top: theme.none,
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: '2fr 1fr',
    gridGap: theme.none,
    marginTop: theme.spacing(4),
    maxWidth: theme.contentMaxWidth,
  }),
});

const AddAllOrderLinesToCart = styled(SmallButton, {
  font: { weight: theme.fontWeight.bold },
  fontSize: theme.zeta,
  letterSpacing: theme.letterSpacing.medium,
  backgroundColor: theme.blueDark,
  color: theme.white,
  width: '100%',
  borderRadius: 'none',
  marginLeft: 'auto',
  minWidth: theme.spacing(23),
  minHeight: theme.spacing(11),
});

const GridBox = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(8),
});

const ListContainer = styled.div({
  font: { size: theme.psi, weight: theme.fontWeight.lighter },
  lineHeight: theme.lineHeight.normal,
  letterSpacing: theme.letterSpacing.medium,
  ...media(theme.mediaQuery.mediaMaxMedium, {
    padding: { x: theme.spacing(4), y: theme.spacing(6) },
  }),
});

const OrderInfoWrapper = styled.div({ padding: { x: theme.spacing(4) } });

const Title = styled.h1({
  font: { size: theme.zeta, weight: theme.fontWeight.bold },
  paddingBottom: theme.spacing(2),
  ...media(theme.mediaQuery.mediaMaxMedium, {
    paddingBottom: theme.spacing(2),
  }),
});

export default OrderPage;
