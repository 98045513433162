import React from 'react';
import { styled, theme } from '../Theme';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { media } from '@glitz/core';
import CustomerModel from '../Customer/Models/CustomerModel.interface';
import OrderHistoryDetailsModel from './Models/OrderHistoryDetailsModel.interface';

type PropType = {
  order: OrderHistoryDetailsModel;
  company: CustomerModel;
};

function OrderDetail({ order, company }: PropType) {
  const {
    translations: {
      'checkoutPage/totalExVat': totalExVatLabel,
      'checkoutPage/total': totalLabel,
      'checkoutPage/ship': freightLabel,
      'checkoutPage/freeShipping': freeFreightText,
      'checkoutPage/orderDetailHeader': orderDetailHeader,
      'checkoutPage/orderLines': orderLinesText,
      'checkoutPage/companyHeader': companyText,
      'orderHistoryPage/deliveryaddress': deliveryAddressText,
      'orderHistoryPage/orderNote': orderNoteText,
      'orderHistoryPage/orderNumber': orderNumberText,
      'orderHistoryPage/status': statusText,
      'orderHistoryPage/placed': placedText,
      'orderHistoryPage/placedBy': placedByText,
      'orderHistoryPage/customerID': customerIDText,
      'orderHistoryPage/totalDiscount': totalDiscountLabel,
    },
    translations,
  } = useAppSettingsData();
  return (
    <Summary>
      <OrderHeading>{orderDetailHeader}</OrderHeading>
      <OrderLine>
        <Label>{orderNumberText}</Label>
        <Value> {order.orderNumber && order.orderNumber} </Value>
      </OrderLine>
      <OrderLine>
        <Label>{orderNoteText}</Label>
        <Value> {order.orderNotes && order.orderNotes} </Value>
      </OrderLine>
      <OrderLine>
        <Label>{placedText}</Label>
        <Value> {order.placedDate && order.placedDate}</Value>
      </OrderLine>
      {order.placedBy && (
        <OrderLine>
          <Label>{placedByText}</Label>
          <Value> {order.placedBy}</Value>
        </OrderLine>
      )}
      <OrderLine>
        <Label>{statusText}</Label> <Value>{translations[order.status]} </Value>
      </OrderLine>
      <OrderLine>
        <Label>{orderLinesText}</Label>
        <Value>{order.numberOfItems && order.numberOfItems} </Value>
      </OrderLine>
      <br />
      <OrderLine>
        <Label>{totalExVatLabel}</Label>
        <Value>{order.totalPriceExclVAT && order.totalPriceExclVAT}</Value>
      </OrderLine>
      {order.totalDiscount && (
        <OrderLine>
          <Label>{totalDiscountLabel}</Label>
          <Value>{order.totalDiscount}</Value>
        </OrderLine>
      )}
      <OrderLine>
        <Label> {freightLabel}</Label>
        <Value>
          {order.totalFreightPrice ? order.totalFreightPrice : freeFreightText}
        </Value>
      </OrderLine>
      <OrderLine>
        <Label>{totalLabel}</Label>
        <Value>{order.totalPrice && order.totalPrice}</Value>
      </OrderLine>
      <br />
      <OrderLine>
        <Label> {companyText}</Label>
        <Value> {company.company && company.company}</Value>
      </OrderLine>
      <OrderLine>
        <Label> {customerIDText}</Label>
        <Value> {company.customerId && company.customerId}</Value>
      </OrderLine>
      <Address>
        <Label> {deliveryAddressText}</Label>
        <AddressValue> {order.shippingAddress?.address} </AddressValue>
      </Address>
    </Summary>
  );
}

export default OrderDetail;

const Summary = styled.div({
  backgroundColor: theme.lightGray,
  padding: {
    xy: theme.spacing(4),
  },
  marginTop: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      xy: theme.spacing(4),
    },
    alignSelf: 'flex-start',
  }),
  ...media(theme.mediaQuery.mediaMaxLarge, {
    marginBottom: theme.spacing(8),
    textAlign: 'right',
  }),
});

const Address = styled.span({
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.loose,
  marginBottom: theme.spacing(4),
  width: '100%',
  whiteSpace: 'nowrap',
  color: theme.black,
  fontSize: theme.beta,
});
const AddressValue = styled.p({
  fontWeight: theme.fontWeight.normal,
  textAlign: 'left',
});

const OrderHeading = styled.h3({
  textAlign: 'center',
  font: {
    size: theme.epsilon,
    weight: theme.fontWeight.bold,
  },
  color: theme.black,
  marginBottom: theme.spacing(4),
  ...media(theme.mediaQuery.mediaMinLarge, {
    textAlign: 'start',
    fontSize: theme.zeta,
  }),
});

const OrderLine = styled.div({
  fontWeight: theme.fontWeight.bold,
  lineHeight: theme.lineHeight.loose,
  display: 'flex',
  marginBottom: theme.spacing(4),
  color: theme.black,
  fontSize: theme.beta,
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginBottom: theme.spacing(2),
  }),
});
const Label = styled.p({
  wordWrap: 'break-word',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    textAlign: 'start',
    marginRight: theme.spacing(2),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, { width: '100%', maxWidth: '50%' }),
});

const Value = styled.span({
  fontWeight: theme.fontWeight.normal,
  width: '50%',
  overflowWrap: 'anywhere',
  ...media(theme.mediaQuery.mediaMaxLarge, { textAlign: 'start' }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    display: 'flex',
    textAlign: 'left',
  }),
});
